import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterModule } from '@angular/router';

import { combineLatest, distinctUntilChanged, filter, startWith, switchMap } from 'rxjs';

import { AuthStore, selectUserRoleType } from '@netz/common/auth';
import { PageHeadingComponent } from '@netz/common/components';
import { GovukTableColumn, TabLazyDirective, TabsComponent } from '@netz/govuk-components';
import { PaginationComponent } from '@shared/components';

import { CcaItemDTO } from 'cca-api';

import {
  DashboardStore,
  selectActiveTab,
  selectItems,
  selectPage,
  selectPageSize,
  selectTotal,
  WorkflowItemsAssignmentType,
} from '../../+store';
import { DashboardItemsListComponent } from '../../components/dashboard-items-list';
import { WorkflowItemsService } from '../../services/workflow-items.service';

const DEFAULT_TABLE_COLUMNS: GovukTableColumn<CcaItemDTO>[] = [
  { field: 'taskType', header: 'Task', isSortable: false },
  { field: 'taskAssignee', header: 'Assigned to', isSortable: false },
  { field: 'daysRemaining', header: 'Days remaining', isSortable: false },
  { field: 'businessId', header: 'Target unit ID', isSortable: false },
  { field: 'accountName', header: 'Target unit', isSortable: false },
  { field: 'sectorAcronym', header: 'Sector ID', isSortable: false },
  { field: 'sectorName', header: 'Sector', isSortable: false },
];

const getTableColumns = (activeTab: WorkflowItemsAssignmentType): GovukTableColumn<CcaItemDTO>[] => {
  const cols = DEFAULT_TABLE_COLUMNS;

  return cols.filter((column) => {
    return activeTab === 'assigned-to-others' || column.field !== 'taskAssignee';
  });
};

@Component({
  selector: 'cca-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styles: `
    .x-scroll {
      overflow-x: scroll;

      .max-content-width {
        min-width: 100%;
        width: max-content;
      }
    }
  `,
  providers: [WorkflowItemsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PageHeadingComponent,
    TabsComponent,
    TabLazyDirective,
    NgTemplateOutlet,
    PaginationComponent,
    RouterModule,
    DashboardItemsListComponent,
  ],
})
export class DashboardPageComponent implements OnInit {
  private readonly workflowItemsService = inject(WorkflowItemsService);
  private readonly dashboardStore = inject(DashboardStore);
  private readonly authStore = inject(AuthStore);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly destroy$ = inject(DestroyRef);

  protected readonly pageSize = this.dashboardStore.select(selectPageSize)();
  protected readonly isLoading = signal(false);

  protected readonly activeTab$ = toObservable(this.dashboardStore.select(selectActiveTab));
  protected readonly page$ = toObservable(this.dashboardStore.select(selectPage));

  protected readonly role = computed(() => this.authStore.select(selectUserRoleType)());
  protected readonly activeTab = computed(() => this.dashboardStore.select(selectActiveTab)());
  protected readonly items = computed(() => this.dashboardStore.select(selectItems)());
  protected readonly total = computed(() => this.dashboardStore.select(selectTotal)());
  protected readonly page = computed(() => this.dashboardStore.select(selectPage)());
  protected readonly tableColumns = computed(() => getTableColumns(this.activeTab()));

  ngOnInit(): void {
    this.activatedRoute.fragment
      .pipe(
        startWith(this.role() === 'OPERATOR' ? 'assigned-to-others' : 'assigned-to-me'),
        distinctUntilChanged(),
        filter((fragment) => !!fragment),
        takeUntilDestroyed(this.destroy$),
      )
      .subscribe((tab: WorkflowItemsAssignmentType) => {
        this.dashboardStore.setPage(1);
        this.dashboardStore.setActiveTab(tab);
      });

    combineLatest([this.activeTab$, this.page$])
      .pipe(
        switchMap(([activeTab, page]) => {
          this.isLoading.set(true);
          return this.workflowItemsService.getItems(activeTab, page, this.pageSize);
        }),
        takeUntilDestroyed(this.destroy$),
      )
      .subscribe(({ items, totalItems }) => {
        this.dashboardStore.setItems(items);
        this.dashboardStore.setTotal(totalItems);
        this.isLoading.set(false);
      });
  }

  changePage(page: number) {
    this.dashboardStore.setPage(page);
  }
}
